import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Layout, SEO } from '../components';
import DocumentsList from '../components/documents/DocumentsList';
import Clouds from '../assets/images/clouds.svg';
import {
  FETCH_MORE_DOCUMENTS_QUERY,
  FETCH_FEATURED_DOCUMENTS_QUERY,
} from '../graphql/queries/documents';
import PasswordProtect from '../components/PasswordProtect';
import { getSessionPassword } from '@src/utils/cookie';

import { CountdownTimer } from '../components/UI/FlipClock';

const correctPassword = getSessionPassword() === process.env.GATSBY_FOLDER_PASSWORD || false;

const ModuleSubCategoryDocumentsPage = ({
  data,
  data: {
    timers,
    subCategoryOption,
    subCategoryPage: { module, heading: subPageCategoryHeading },
    datocms: {
      documents,
      documentsCount: { count: totalDocuments },
    },
  },
  pageContext: { categories, id, module: moduleId },
}) => {
  const {
    description,
    seo,
    seoKeywords,
    subCategoryOptionHeading: heading,
    isLocked,
  } = subCategoryOption.edges[0].node;

  let stillLocked = isLocked;

  if (isLocked && correctPassword) {
    stillLocked = false;
  }

  const PAGE_SIZE = 100;
  const [combinedDocuments, setCombinedDocuments] = useState(
    documents.filter((doc) => !doc.isFeatured && !doc.freeSample),
  );
  const [currentPage, setCurrentPage] = useState(0);
  const freeDocuments = documents.filter((doc) => doc.freeSample && !doc.freeDocument);
  const context = {
    clientName: 'dato-cms',
  };
  const variables = {
    id,
    module: moduleId,
    categories,
    first: PAGE_SIZE,
    skip: (currentPage + 1) * PAGE_SIZE,
  };
  const { data: featuredDocumentsData, loading: featuredDocumentsLoading } = useQuery(
    FETCH_FEATURED_DOCUMENTS_QUERY,
    {
      context,
      variables,
    },
  );

  // const hasNextPage =
  //   combinedDocuments.length >= (currentPage + 1) * PAGE_SIZE ||
  //   combinedDocuments.length !== totalDocuments;

  const hasNextPage = totalDocuments >= (currentPage + 1) * PAGE_SIZE;

  const onDocumentFetchSuccess = (data) => {
    if (data) {
      const newDocuments = [...combinedDocuments, ...data.documents];
      setCombinedDocuments(newDocuments);
    }
  };

  const [fetchMoreDocuments, { loading, networkStatus }] = useLazyQuery(
    FETCH_MORE_DOCUMENTS_QUERY,
    {
      onCompleted: onDocumentFetchSuccess,
      notifyOnNetworkStatusChange: true,
    },
  );
  const isRefetching = networkStatus === 3;

  const orderedTimers = React.useMemo(() => {
    return timers?.group?.map(({ edges, examBoard }) => {
      const groups = {}
      for (const { node, node: { title } } of edges) {
        const regex = /\d([A-Za-z])(?![A-Za-z])/;
        const matched = title?.match(regex);

        if (!matched) {
          if (!groups.unmatched) groups.unmatched = [];
          groups.unmatched = [
            ...groups.unmatched,
            node
          ];
          continue;
        };

        const [_, letter] = matched;
        if (!groups[letter]) {
          groups[letter] = [];
        }
        groups[letter].push(node);
      }
      const formattedEdges = Object.values(groups).map(papers => papers.sort((a, b) => b.title - a.title)).flat();
      return {
        edges: formattedEdges,
        examBoard
      }
    })
  }, [timers]);

  const numOfTimers = orderedTimers.reduce((total, { edges }) => total += edges?.length ?? 0, 0);

  const handleFetchMore = () => {
    fetchMoreDocuments({
      variables,
      context,
    });

    setCurrentPage(currentPage + 1);
  };

  const renderFeaturedDocuments = () => {
    if (!!featuredDocumentsLoading) return <p>Loading...</p>;

    return (
      !!featuredDocumentsData.featuredDocuments.length && (
        <div className="documents-list">
          <div className="category-breadcrumb-heading">
            <h3>Featured documents:</h3>
          </div>
          <DocumentsList documents={featuredDocumentsData.featuredDocuments} />
        </div>
      )
    );
  };

  const renderFreeDocuments = () =>
    freeDocuments.length > 0 && (
      <div className="documents-list">
        <div className="category-breadcrumb-heading">
          <h3>Free samples:</h3>
        </div>
        <DocumentsList documents={freeDocuments} />
      </div>
    );

  const renderDocumentsHeading = () =>
    documents[0] && (
      <div className="category-breadcrumb-heading">
        {/* <h3 style={{ marginBottom: 15 }}>
          {documents[0].documentCategory[documents[0].documentCategory.length - 1].name}:
        </h3> */}
        <p>Total of {totalDocuments}</p>
      </div>
    );

  return (
    <MathJaxContext>
      <Layout>
        <SEO
          description={seo && seo.description}
          image={seo && seo.image}
          title={seo && seo.title}
          seoKeywords={seoKeywords}
        />
        <div>
          <div>
            <div className="container">
            <div className={`srow mobile:x-centered ${numOfTimers < 4 ? 'x-centered' : ''}`}>
                {!!orderedTimers?.length &&
                  orderedTimers.map(({ edges }) =>
                    !!edges?.length && (
                      edges.map(({ endDate, title, daysOnly }, k) => (
                        <div className="column narrow mb-1" key={`clock ${k}`}>
                          <CountdownTimer
                            endDate={endDate}
                            title={title}
                            daysOnly={daysOnly}
                          />
                        </div>
                      ))
                    )
                  )
                }
              </div>
              <div className="w-layout-grid grid hero">
                <div className="content-block">
                  <div style={{ marginBottom: '5px' }}>
                    <a className="page-breadcrums" href={`/modules/${module.slug}`}>
                      {module.title}
                    </a>
                    {' > '}
                    <a
                      className="page-breadcrums"
                      href={`/modules/${module.slug}/${subPageCategoryHeading.slug}`}
                    >
                      {subPageCategoryHeading.name}
                    </a>
                  </div>
                  <h1 className="content-h2">{heading.name}</h1>
                  {/* <h1 className="content-h2">{module.title}</h1> */}
                  {/* <h2>{heading.name}</h2> */}
                  <br />
                  <MathJax hideUntilTypeset="first">
                    <ReactMarkdown className="paragraph" children={description} />
                  </MathJax>
                </div>
                <img src={module.image.url} alt={module.image.alt} />
              </div>
            </div>
            <img src={Clouds} alt="Clouds" className="pattern-left" />
          </div>
          {stillLocked === true && <PasswordProtect />}
          {!stillLocked && (
            <article className="content-section">
              <div className="content-block container">
                {renderFeaturedDocuments()}
                {renderFreeDocuments()}
                {renderDocumentsHeading()}
                <DocumentsList documents={combinedDocuments} showNoData type={heading.name} />
                <br />
                {hasNextPage && (
                  <button
                    className="button-blue w-button"
                    disabled={isRefetching}
                    loading={String(isRefetching)}
                    onClick={handleFetchMore}
                    type="button"
                  >
                    {loading ? 'Loading...' : 'Load more'}
                  </button>
                )}
              </div>
            </article>
          )}
        </div>
      </Layout>
    </MathJaxContext>
  );
};

export default ModuleSubCategoryDocumentsPage;

const query = graphql`
  query SUB_CATEGORY_SUB_CATEGORY_OPTIONS_DOCUMENTS_QUERY2(
    $module: DATOCMS_ItemId
    $id: String!
    $subCategoryOptionId: String
    $categories: [DATOCMS_ItemId]
    $pathName: String
  ) {
    timers: allDatoCmsCountdownTimer(filter: { path: { eq: $pathName } }, sort: { fields: title }) {
      group(field: examBoard) {
        edges {
            node {
            daysOnly
            endDate
            examBoard
            path
            title
            }
        }
        examBoard: fieldValue
      }
    }
    subCategoryOption: allDatoCmsSubCategoryOption(filter: { id: { eq: $subCategoryOptionId } }) {
      edges {
        node {
          id
          description
          seo {
            description
            title
            image {
              url
            }
          }
          seoKeywords {
            keyword
          }
          isLocked
          subCategoryOptionHeading {
            slug
            name
            originalId
            id
          }
        }
      }
    }
    subCategoryPage: datoCmsModuleSubCategoryPage(id: { eq: $id }) {
      module {
        title
        slug
        image {
          alt
          url
        }
      }
      heading {
        name
        slug
      }
    }
    datocms {
      documentsCount: _allDocumentsMeta(
        filter: {
          documentCategory: { allIn: $categories }
          module: { eq: $module }
          isFeatured: { eq: "false" }
          freeSample: { eq: "false" }
        }
      ) {
        count
      }
      freeDocumentsCount: _allDocumentsMeta(
        filter: {
          documentCategory: { allIn: $categories }
          module: { eq: $module }
          isFeatured: { eq: "false" }
          freeSample: { eq: "true" }
        }
      ) {
        count
      }
      documents: allDocuments(
        filter: {
          documentCategory: { allIn: $categories }
          module: { eq: $module }
          isFeatured: { eq: "false" }
        }
        orderBy: name_ASC
        first: "100"
      ) {
        id
        name
        module {
          slug
        }
        documentCategory {
          name
          id
          slug
        }
        price
        priceCode
        fileUrl
        freeDocument
        freeSample
        isFeatured
        hasPassword
      }
    }
  }
`;
export { query };
